import dayjs from 'dayjs'
import elasticlunr, { Index } from 'elasticlunr'
import PropTypes from 'prop-types'
import React, { useEffect, useCallback, useMemo, useRef, useState } from 'react'

import DateFilter from '../components/date-filter'
import EventList from '../components/event-list'
import EventsByDate from '../components/events-by-date'
import SearchFilter from '../components/search-filter'
import SelectFilter, { SelectFilterOption } from '../components/select-filter'
import Tab from '../components/tab'
import Tabs from '../components/tabs'
import TagFilter, { TagFilterOption } from '../components/tag-filter'
import ThemeBanner from '../components/theme-banner'
import { useVeeziWebSessionsAll } from '../hooks/veezi-web-sessions'
import styles from './events.module.css'
import SEO from '../components/seo'
import {
  filterSessionsByDate,
  groupEventsByCategory,
  getCategoriesToShow,
  groupEventsByTheme,
  getThemesToShow,
  getDatesWithEvents,
  filterByDate,
  getSearchIndex,
  containsAll,
  filterEventsByAccessibility,
} from '../util/events'

export const EventsTemplate = ({
  events,
  themes,
  categories,
  location: { hash },
  searchIndex,
}) => {
  const tabs = ['categories', 'dates', 'themes', 'accessibility', 'search']
  let extraFilter = useRef(null)

  const [tab, setTab] = useState('categories')
  const [selectedCategory, setSelectedCategory] = useState('all')
  const [selectedTheme, setSelectedTheme] = useState('all')
  const [selectedAccessibilities, setSelectedAccessibilities] = useState({
    wheelchair: true,
  })
  const [selectedDate, setSelectedDate] = useState(dayjs())
  const [searchKeyword, setSearchKeyword] = useState('')
  const [dateRange, setDateRange] = useState([
    dayjs(selectedDate).startOf('week'),
    dayjs(selectedDate)
      .startOf('week')
      .add(6, 'days'),
  ])

  const startDate = dateRange[0]
  const endDate = dateRange[1]
  const veeziWebSessions = (useVeeziWebSessionsAll() || []).filter(
    s => events.findIndex(e => e.veeziFilmId === s.FilmId) > -1
  )
  const veeziWebSessionsForDate = useMemo(
    () =>
      filterSessionsByDate({
        selectedDate,
        veeziWebSessions,
        startDate,
        endDate,
      }),
    [selectedDate, startDate, endDate, veeziWebSessions]
  )

  useEffect(() => {
    const defaultTab = 'themes'
    const resetChildren = () => {
      setSelectedCategory('all')
      setSelectedTheme('all')
    }

    if (hash && hash.includes('-')) {
      const t = hash.substring(1, hash.indexOf('-'))
      const id = hash.substring(hash.indexOf('-') + 1, hash.length)
      if (tabs.includes(t)) {
        if (tab !== t) setTab(t)
        if (t === 'categories')
          (id === 'all' ||
            (categories || []).findIndex(c => c.id === id) > -1) &&
            setSelectedCategory(id)
        else if (t === 'themes')
          (id === 'all' || (themes || []).findIndex(t => t.id === id) > -1) &&
            setSelectedTheme(id)
      } else {
        setTab(defaultTab)
        resetChildren()
      }
    } else {
      const t =
        hash && tabs.includes(hash.substring(1, hash.length))
          ? hash.substring(1, hash.length)
          : defaultTab

      if (tab !== t) setTab(t)
      resetChildren()
    }
  }, [hash, tabs, tab, categories, themes])

  const eventsByCategory = useMemo(() => groupEventsByCategory(events), [
    events,
  ])

  const categoriesToShow = useMemo(
    () => getCategoriesToShow(categories, eventsByCategory),
    [eventsByCategory, categories]
  )

  const eventsByTheme = useMemo(() => groupEventsByTheme(events), [events])

  const themesToShow = useMemo(() => getThemesToShow(themes, eventsByTheme), [
    eventsByTheme,
    themes,
  ])

  events = events.map(f => {
    if (f.themes && f.themes.length)
      f.themes = f.themes.filter(
        ft => themes.findIndex(t => t.id === ft.id) > -1
      )
    return f
  })
  const datesWithEvent = useMemo(
    () => getDatesWithEvents({ events, veeziWebSessions }),
    [events, veeziWebSessions]
  )

  // reset filter
  extraFilter.current = null
  let eventsToShow

  if (tab === 'categories') {
    eventsToShow =
      selectedCategory === 'all' ? events : eventsByCategory[selectedCategory]
  } else if (tab === 'themes') {
    eventsToShow =
      selectedTheme === 'all' ? events : eventsByTheme[selectedTheme]
  } else if (tab === 'accessibility') {
    eventsToShow = filterEventsByAccessibility(events, selectedAccessibilities)
  } else if (tab === 'dates') {
    eventsToShow = events.filter(e =>
      filterByDate(e, veeziWebSessionsForDate, selectedDate, dateRange)
    )
  } else if (tab === 'search') {
    elasticlunr.clearStopWords()
    const store = Index.load(searchIndex)
    if (searchKeyword && searchKeyword.trim() !== '') {
      const search = getSearchIndex(store, searchKeyword)
      eventsToShow = events.filter(
        e => search.findIndex(s => e.slug === s.slug) > -1
      )
    } else {
      eventsToShow = events
    }
  }

  let eventList
  if (tab === 'dates') {
    if (selectedDate === 'all') {
      eventList = (
        <EventsByDate
          events={eventsToShow}
          veeziWebSessions={veeziWebSessionsForDate}
        ></EventsByDate>
      )
    } else {
      const eventsWithSessions = eventsToShow.map(f =>
        Object.assign({}, f, {
          sessions: (veeziWebSessionsForDate || []).filter(
            s => s.FilmId === f.veeziFilmId
          ),
        })
      )
      eventList = <EventList events={eventsWithSessions} />
    }
  } else {
    eventList = (
      <EventList events={eventsToShow} extraFilter={extraFilter.current} />
    )
  }

  const onTabClick = useCallback(tab => {
    window.location = '#' + tab
    setTab(tab)
  }, [])

  const onCategoryClick = useCallback(category => {
    window.location.hash = '#categories-' + category
  }, [])

  const onThemeClick = useCallback(theme => {
    window.location.hash = '#themes-' + theme
  }, [])

  const searchIcon = (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.427 15.427a8.5 8.5 0 112-2l5.585 5.585c.55.55.546 1.431 0 1.976l-.023.023a1.398 1.398 0 01-1.976 0l-5.585-5.585-.001.001zM8.5 15a6.5 6.5 0 100-13 6.5 6.5 0 000 13z"
        fill="#FFF"
      />
    </svg>
  )

  return (
    <>
    <SEO 
      title={"Current and upcoming events happening at The Projector"}
      description={"Find a list of current and upcoming unique events happening at The Projector Singapore including dance parties, live performances and comedy shows."}
    />
      <div className="container flex flex-col items-stretch">
        <h1 className={styles.title}>
          Events: What's showing
          <button
            className={styles.searchIconBtn}
            onClick={() => onTabClick('search')}
          >
            <span>Search</span>
            {searchIcon}
          </button>
        </h1>
        <Tabs
          title="Browse by"
          selectClass={tab === 'search' ? styles.mobileHidden : ''}
          className={styles.tabs}
        >
          <Tab
            title="Categories"
            dropdownTitle="Browse By Categories"
            isActive={tab === 'categories'}
            onClick={() => onTabClick('categories')}
          >
            <SelectFilter
              onChange={option => onCategoryClick(option.value)}
              dropdownAllTitle="All Categories"
            >
              {categoriesToShow.map((category, i) => (
                <SelectFilterOption
                  key={i}
                  value={category.id}
                  title={category.title}
                  isSelected={selectedCategory === category.id}
                />
              ))}
            </SelectFilter>
          </Tab>
          <Tab
            title="Dates"
            dropdownTitle="Browse By Dates"
            isActive={tab === 'dates'}
            onClick={() => onTabClick('dates')}
          >
            <DateFilter
              onChange={setSelectedDate}
              onRangeChange={r => setDateRange([dayjs(r[0]), dayjs(r[6])])}
              showHistory={false}
              showAll={true}
              value={selectedDate}
              allowedDates={datesWithEvent}
              showCalendarWeeks={true}
            />
          </Tab>
          <Tab
            title="Programmes"
            dropdownTitle="Browse By Programmes"
            isActive={tab === 'themes'}
            onClick={() => onTabClick('themes')}
          >
            <SelectFilter
              onChange={option => onThemeClick(option.value)}
              dropdownAllTitle="All Programmes"
            >
              {themesToShow.map((theme, i) => (
                <SelectFilterOption
                  key={i}
                  value={theme.id}
                  title={theme.title}
                  isSelected={selectedTheme === theme.id}
                />
              ))}
            </SelectFilter>
          </Tab>
          <Tab
            title="Accessibility"
            dropdownTitle="Browse By Accessibility"
            isActive={tab === 'accessibility'}
            onClick={() => onTabClick('accessibility')}
          >
            <TagFilter
              onChange={value =>
                setSelectedAccessibilities(
                  Object.assign({}, selectedAccessibilities, {
                    [value]: !selectedAccessibilities[value],
                  })
                )
              }
            >
              <TagFilterOption
                title="Wheelchair-Friendly"
                value="wheelchair"
                isSelected={selectedAccessibilities['wheelchair']}
              />
              <TagFilterOption
                title="Closed Captions (CC)"
                value="cc"
                isSelected={selectedAccessibilities['cc']}
              />
            </TagFilter>
          </Tab>
          <Tab
            title="Search"
            titleIcon={searchIcon}
            isActive={tab === 'search'}
            onClick={() => onTabClick('search')}
            headerClass={styles.search}
            hidden={true}
          >
            <SearchFilter
              onChange={k => setSearchKeyword(k)}
              onClose={() => onTabClick('categories')}
            />
          </Tab>
        </Tabs>
        {tab === 'dates' ? <ThemeBanner date={selectedDate} /> : null}
        {eventsToShow.length ? (
          eventList
        ) : (
          <div className={styles.noEvents}>No events found.</div>
        )}
      </div>
    </>
  )
}

EventsTemplate.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.arrayOf(PropTypes.object),
  themes: PropTypes.arrayOf(PropTypes.object),
}
