import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { EventsTemplate } from '../templates/events'

const EventsPage = ({ data, location }) => {
  const events = data.events.edges.map(e => ({
    ...e.node.frontmatter,
    ...e.node.fields,
    venue: e.node.fields.venue && {
      ...e.node.fields.venue.fields,
      ...e.node.fields.venue.frontmatter,
    },
    themes:
      e.node.fields.themes &&
      e.node.fields.themes.map(c => ({
        ...c.fields,
        ...c.frontmatter,
      })),
    categories:
      e.node.fields.categories &&
      e.node.fields.categories.map(c => ({
        ...c.fields,
        ...c.frontmatter,
      })),
  }))

  const categories = data.categories.edges.map(c => ({
    ...c.node.frontmatter,
    ...c.node.fields,
  }))

  const themes = data.themes.edges.map(c => ({
    ...c.node.frontmatter,
    ...c.node.fields,
  }))

  const searchIndex = data.searchIndex && data.searchIndex.index

  return (
    <EventsTemplate
      events={events}
      categories={categories}
      themes={themes}
      location={location}
      searchIndex={searchIndex}
    />
  )
}

const ContentShape = PropTypes.shape({
  allMarkdownRemark: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          frontmatter: PropTypes.object,
        }),
      })
    ),
  }),
})

EventsPage.propTypes = {
  data: PropTypes.shape({
    events: ContentShape,
  }),
}

export default EventsPage

export const pageQuery = graphql`
  query Events {
    events: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "films-and-events" } }
        frontmatter: { eventTypes: { in: ["event"] } }
      }
      sort: {
        fields: [frontmatter___order, frontmatter___startDate]
        order: ASC
      }
    ) {
      edges {
        node {
          fields {
            ...EventFieldWithVenue
          }
          frontmatter {
            ...EventFrontmatter
          }
        }
      }
    }
    themes: allMarkdownRemark(
      filter: { fields: { collection: { eq: "themes" } } }
    ) {
      edges {
        node {
          ...AllThemes
        }
      }
    }
    categories: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "categories" } }
        frontmatter: { appliesTo: { in: ["events"] } }
      }
    ) {
      edges {
        node {
          ...AllCategories
        }
      }
    }
    searchIndex: siteSearchIndex {
      index
    }
  }
`
